import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEamil] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    emailjs.init('pcSTHca8NCsEWdTVx');
  }, []);

  const sendContactForm = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.sendForm('service_3x2wiwa', 'template_e6f5tw9', e.target).then(
      () => {
        setSent(true);
        setLoading(false);
      },
      () => {
        setError('Something went wrong. Please try again later.');
        setLoading(false);
      }
    );
  };

  return (
    <form className="contact-form" onSubmit={sendContactForm}>
      <h3 className="form-heading heading-2 white mb-20">Contact</h3>
      <input
        type="text"
        placeholder="Your name"
        className="form-field"
        required
        value={name}
        name="from_name"
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="email"
        placeholder="Your email"
        className="form-field"
        required
        value={email}
        name="from_email"
        onChange={(e) => setEamil(e.target.value)}
      />
      <input
        type="text"
        placeholder="Phone number"
        className="form-field"
        required
        value={phone}
        name="from_phone"
        onChange={(e) => setPhone(e.target.value)}
      />
      <textarea
        placeholder="Message"
        className="form-text-field"
        required
        value={message}
        name="from_message"
        onChange={(e) => setMessage(e.target.value)}
      />
      {loading && (
        <button type="button" className="button button-primary mt-10" disabled>
          Sending...
        </button>
      )}
      {sent && (
        <button type="button" className="button button-primary mt-10" disabled>
          Thank you!
        </button>
      )}
      {!loading && !sent && (
        <button type="submit" className="button button-primary mt-10">
          Send Message
        </button>
      )}
      {error && <p className="error">{error}</p>}
    </form>
  );
}

export default ContactForm;
