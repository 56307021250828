import React from 'react';

function RecentProject({ img }) {
  return (
    <div className="col-lg-4 col-md-6">
      <a href={img}>
        <img src={img} alt="project" className="project-img" />
      </a>
    </div>
  );
}

export default RecentProject;
