import React from 'react';
import { FaFileArchive } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function BrochersItem({ text, link }) {
  return (
    <Link to={link} className="bruchures-item">
      <span className="icon">
        <FaFileArchive />
      </span>
      {text}
    </Link>
  );
}

export default BrochersItem;
