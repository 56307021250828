import { useEffect } from 'react';
import bannerBg from '../assets/images/about/about-header.jpg';
// import About2 from '../components/About2';
import PageBanner from '../components/PageBanner';
import Projects from '../components/Projects';
// import Process from '../components/Process';
// import Team from '../components/Team';
// import Vision from '../components/Vision';
import WhyUs from '../components/WhyUs';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="i4Framing"
        mainHeading="About us"
        currentPage="About"
      />
      {/* <About2 /> */}
      {/* <Vision /> */}
      <WhyUs />
      <Projects />
      {/* <Team /> */}
      {/* <Process /> */}
    </>
  );
}

export default About;
