import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { SRLWrapper } from 'simple-react-lightbox';
import galleryItem1 from '../../assets/images/portfolio/portfolio-item1.jpg';
import galleryItem2 from '../../assets/images/portfolio/portfolio-item2.jpg';
import galleryItem3 from '../../assets/images/portfolio/portfolio-item3.jpg';
import galleryItem4 from '../../assets/images/portfolio/portfolio-item4.jpg';
import galleryItem5 from '../../assets/images/portfolio/portfolio-item5.jpg';
import galleryItem6 from '../../assets/images/portfolio/portfolio-item6.jpg';
import galleryItem7 from '../../assets/images/portfolio/portfolio-item7.jpg';
import galleryItem8 from '../../assets/images/portfolio/portfolio-item8.jpg';

function PortfolioProject() {
  return (
    <div className="portfolio-gallery-wrapper section-padding">
      <Tabs>
        <div className="container">
          <TabList className="nav nav-tabs">
            <Tab className="nav-item">
              <button type="button" className="nav-link">
                Commercial
              </button>
            </Tab>
            <Tab className="nav-item">
              <button type="button" className="nav-link">
                Design
              </button>
            </Tab>
            <Tab className="nav-item">
              <button type="button" className="nav-link">
                Exclusive
              </button>
            </Tab>

            <Tab className="nav-item">
              <button type="button" className="nav-link">
                Interior
              </button>
            </Tab>
            <Tab className="nav-item">
              <button type="button" className="nav-link">
                Construction
              </button>
            </Tab>
          </TabList>
        </div>
        <SRLWrapper>
          <TabPanel>
            <div className="gallery-items">
              <div className="gallery-item item-1">
                <a href={galleryItem1}>
                  <img src={galleryItem1} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-2">
                <a href={galleryItem2}>
                  <img src={galleryItem2} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-3">
                <a href={galleryItem3}>
                  <img src={galleryItem3} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-4">
                <a href={galleryItem4}>
                  <img src={galleryItem4} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-5">
                <a href={galleryItem5}>
                  <img src={galleryItem5} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-6">
                <a href={galleryItem6}>
                  <img src={galleryItem6} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-7">
                <a href={galleryItem7}>
                  <img src={galleryItem7} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-8">
                <a href={galleryItem8}>
                  <img src={galleryItem8} alt="" className="gallery-img" />
                </a>
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="gallery-items">
              <div className="gallery-item item-1">
                <a href={galleryItem1}>
                  <img src={galleryItem1} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-2">
                <a href={galleryItem2}>
                  <img src={galleryItem2} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-3">
                <a href={galleryItem3}>
                  <img src={galleryItem3} alt="" className="gallery-img" />
                </a>
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="gallery-items">
              <div className="gallery-item item-1">
                <a href={galleryItem1}>
                  <img src={galleryItem1} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-2">
                <a href={galleryItem2}>
                  <img src={galleryItem2} alt="" className="gallery-img" />
                </a>
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="gallery-items">
              <div className="gallery-item item-1">
                <a href={galleryItem1}>
                  <img src={galleryItem1} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-2">
                <a href={galleryItem2}>
                  <img src={galleryItem2} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-3">
                <a href={galleryItem3}>
                  <img src={galleryItem3} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-4">
                <a href={galleryItem4}>
                  <img src={galleryItem4} alt="" className="gallery-img" />
                </a>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="gallery-items">
              <div className="gallery-item item-1">
                <a href={galleryItem1}>
                  <img src={galleryItem1} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-2">
                <a href={galleryItem2}>
                  <img src={galleryItem2} alt="" className="gallery-img" />
                </a>
              </div>
              <div className="gallery-item item-3">
                <a href={galleryItem3}>
                  <img src={galleryItem3} alt="" className="gallery-img" />
                </a>
              </div>
            </div>
          </TabPanel>
        </SRLWrapper>
      </Tabs>
    </div>
  );
}

export default PortfolioProject;
