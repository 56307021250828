import React from 'react';

function ServiceDetailsCard({ icon, heading, text }) {
  return (
    <div className="col-md-4">
      <div className="content-card mt-40">
        <img src={icon} alt="" className="card-icon" />
        <h3 className="card-heading">{heading}</h3>
        <p className="card-text">{text}</p>
      </div>
    </div>
  );
}

export default ServiceDetailsCard;
