import React from 'react';

function BrandItem({ brand, link }) {
  return (
    <div className="slider-item">
      <a href={link} target="_blank" rel="noreferrer">
        <img src={brand} alt="brand" className="slider-img" />
      </a>
    </div>
  );
}

export default BrandItem;
