import bannerBg from '../assets/images/banner-bg.png';
import PageBanner from '../components/PageBanner';
import SinglePost from '../components/SinglePost';

function SingleBlog() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="post"
        mainHeading="Single Post"
        currentPage="Single Post"
        nasted
        nastedPage="Blog"
        nastedLink="/blog"
      />
      <SinglePost />
    </>
  );
}

export default SingleBlog;
