import React from 'react';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import {
  FaFacebookF,
  FaPaperPlane,
  FaStopwatch,
  // eslint-disable-next-line prettier/prettier
  FaInstagram,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MenuContent = ({ onItemClick }) => {
  const onClickHandler = (e) => {
    e.stopPropagation();
    onItemClick();
  };

  return (
    <div className="mobile-menu-bottom">
      <ul>
        <li className="bottom-item">
          <FaStopwatch className="icon" />
          Mon - Sat 9:00am to 6:00pm
        </li>
        <li className="bottom-item">
          <AiOutlineMail className="icon" />
          <a href="mailto:contact@i4framing.com" onClick={onClickHandler}>
            contact@i4framing.com
          </a>
        </li>
        <li className="bottom-item">
          <AiOutlinePhone className="icon" />
          <a href="tel:+13214610792" onClick={onClickHandler}>
            (321) 461-0792
          </a>
        </li>
      </ul>
      <div className="bottom-btn text-center">
        <Link
          to="/contact"
          className="button button-primary"
          onClick={onClickHandler}
        >
          <FaPaperPlane className="icon" />
          Contact Us
        </Link>
      </div>
      <div className="social-icons">
        <a
          href="https://www.facebook.com/profile.php?id=100087386364243"
          onClick={onClickHandler}
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/i4framing/"
          target="_blank"
          rel="noreferrer"
          onClick={onClickHandler}
        >
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};

export default MenuContent;
