import React from 'react';

function GalleryItem({ thumb }) {
  return (
    <div className="slider-item">
      <div className="row">
        <div className="col-lg-12">
          <div
            className="slider-thumb bg-cover"
            style={{ backgroundImage: `url(${thumb})` }}
          />
        </div>
      </div>
    </div>
  );
}

export default GalleryItem;
