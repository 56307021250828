import React from 'react';
import {
  FaCalendarAlt,
  FaChevronLeft,
  FaChevronRight,
  FaCommentAlt,
  // eslint-disable-next-line prettier/prettier
  FaShareAlt
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import thumb2 from '../../assets/images/blog/blog-thum1.png';
import thumb1 from '../../assets/images/blog/blog-thum8.png';
import avatar1 from '../../assets/images/blog/comment-avatar1.png';
import avatar2 from '../../assets/images/blog/comment-avatar2.png';
import BlogGridItem from '../BlogGrid/BlogGridItem';
import BlogSidebar from '../BlogSidebar';
import CommentBox from './CommentBox';
import CommentForm from './CommentForm';
import Tags from './Tags';

function SinglePost() {
  return (
    <section className="single-post-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="left-content">
              <div className="post-details">
                <div className="blog-card">
                  <div
                    className="card-thumb bg-cover"
                    style={{ backgroundImage: `url(${thumb1})` }}
                  />
                  <span className="category">Interior</span>
                  <div className="blog-card-content">
                    <ul className="blog-meta">
                      <li className="meta-item">
                        <span className="meta-icon">
                          <FaCalendarAlt />
                        </span>
                        May 16, 2021
                      </li>
                      <li className="meta-item comment">
                        <span className="meta-icon">
                          <FaCommentAlt />
                        </span>
                        0 comments
                      </li>
                      <li className="meta-item">
                        <Link to="/" className="meta-item-link">
                          <span className="meta-icon">
                            <FaShareAlt />
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 className="heading-1 post-heading">
                  Advantages of High-Tech projects
                </h3>
                <p className="body-text d-flex">
                  <span className="first-letter mr-15">A</span>
                  <span>
                    mong the available technologies for private construction is
                    the creation of a modern space in a creative and bold style.
                    The layout and decoration of the high-tech house was taken
                    into service from warm European latitudes, modified for the
                    USA climate. Today, the use of this style has made it
                    possible to prove its right
                  </span>
                </p>
                <p className="body-text">
                  to distribution, to develop a number of distinctive advantages
                  in domestic practice. Literally translated from English, the
                  name of technology means high technology. Indeed, high-tech
                  buildings represent a new cultural trend, in which innovative
                  materials are combined, advanced methods of arranging the
                  foundation and communications are used, and the general
                  concept of ergonomics has been changed.
                </p>
                <div className="post-quote">
                  <h4 className="quote-title">
                    “The desire to purchase a high-tech house is understandable.
                    Every year you want something new, but time definitely does
                    not stand still.”
                  </h4>
                </div>
                <p className="body-text">
                  Literally translated from English, the name of technology
                  means high technology. Indeed, high-tech buildings represent a
                  new cultural trend, in which innovative materials are
                  combined, advanced methods of arranging the foundation and
                  communications are used, and the general concept of ergonomics
                  has been changed. Already in the appearance of such houses,
                  one can see a clear trend of modernism. And this with full
                  confidence can be called the first and main advantage of
                  high-tech architecture - it is a high-tech residential
                  complex.
                </p>
                <div
                  className="post-thumb-2 bg-cover"
                  style={{ backgroundImage: `url(${thumb2})` }}
                />
                <h3 className="heading-2 post-heading-2">
                  High-Tech architecture features
                </h3>
                <p className="body-text">
                  The desire to purchase a high-tech house is understandable.
                  Every year you want something new, but time definitely does
                  not stand still. The popularity of high-tech buildings is due
                  to the crisis, the desire for practicality and the final move
                  out of town. Already at the stage of studying a typical
                  project, one can understand that such a house will have
                  everything that has been lacking for many years in the place
                  of residence: a convenient underground garage, an open space
                  on the roof or a swimming pool.
                  <br />
                  <br />
                  Choosing a complete set of architectural elements often has a
                  strong budget impact. Cost adjustments can be made by revising
                  the material base, reducing the total area of the building or
                  reducing infrastructure. Compact houses built according to
                  standard or individual (modified) projects have the following
                  advantages.
                </p>
              </div>
              <ul className="post-tags">
                <li className="tags-item">Tags:</li>
                <Tags link="#" tag="#Modern" />
                <Tags link="#" tag="#Rofs" />
                <Tags link="#" tag="#Hi-Tech" />
                <Tags link="#" tag="#Building" />
              </ul>

              <div className="project-details-nav blog-post-nav">
                <div className="nav-box">
                  <Link to="#" className="nav-prev nav-action mr-20">
                    <span className="nav-icon">
                      <i className="fas fa-chevron-left" />
                      <FaChevronLeft />
                    </span>
                  </Link>
                  <Link to="#" className="nav-text d-none d-sm-block">
                    Previous post
                  </Link>
                </div>
                <div className="nav-box">
                  <Link to="#" className="nav-text mr-20 d-none d-sm-block">
                    Next post
                  </Link>
                  <Link to="#" className="nav-prev nav-action">
                    <span className="nav-icon">
                      <i className="fas fa-chevron-right" />
                      <FaChevronRight />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <h3 className="heading-1 mb-30">Related posts</h3>
              <div className="row">
                <BlogGridItem
                  sixCol
                  thumb={thumb1}
                  category="Architecture"
                  postDate="Sept 3, 2021"
                  comments=" 3 comments"
                  heading=" How does the construction of a house begin"
                  desc=" All preparatory steps should be performed in a certain
                        order. Before sta..."
                  postUrl="/single-blog"
                />

                <BlogGridItem
                  sixCol
                  thumb={thumb2}
                  category="Facade"
                  postDate="  Aug 8, 2021"
                  comments="   15 comments"
                  heading="  How does the construction of a house begin"
                  desc="  If you would like to receive a professionally prepared
                        interior design…"
                  postUrl="/single-blog"
                />
              </div>
            </div>

            <div className="comments-wrapper">
              <h3 className="heading-1 mb-30">Comments</h3>

              <CommentBox
                avatar={avatar1}
                name="Anna Stewart"
                meta=" July 3, 2021 at 09:20 am"
                comment=" All processes are performed in a single cycle, by one
                  performer. Does this eliminate the overestimation of the cost
                  of attracting various contractors, reduces technical risks,
                  improvements after the commissioning of the object?"
              />

              <CommentBox
                replay
                avatar={avatar2}
                name="Rosalie Martinez"
                meta=" July 3, 2021 at 09:20 am"
                comment=" All processes are performed in a single cycle, by one
                  performer. Does this eliminate the overestimation of the cost
                  of attracting various contractors, reduces technical risks,
                  improvements after the commissioning of the object?"
              />
            </div>

            <div className="post-comment">
              <h3 className="heading-1 mb-30">Leave Reply</h3>
              <CommentForm />
            </div>
          </div>
          <BlogSidebar />
        </div>
      </div>
    </section>
  );
}

export default SinglePost;
