import React from 'react';

function FooterContact({ icon, howToContact, text }) {
  return (
    <div className="col-lg-4">
      <div className="footer-contact d-flex align-items-center">
        <span className="contact-icon">
          <img src={icon} alt="call" />
        </span>
        <div className="contact-info">
          <span className="top-text">{howToContact}</span>
          <p className="main-text">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default FooterContact;
