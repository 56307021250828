import bannerBg from '../assets/images/banner-bg.png';
import PageBanner from '../components/PageBanner';
import SinglePortfolio from '../components/SinglePortfolio';

function PortfolioDetails() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="Works"
        mainHeading="Projects"
        currentPage="Interior Design"
        nasted
        nastedLink="/portfolio"
        nastedPage="Portfolio"
      />
      <SinglePortfolio />
    </>
  );
}

export default PortfolioDetails;
