import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/images/blog/blog-thum1.png';
import thumb2 from '../../assets/images/blog/blog-thum2.png';
import thumb3 from '../../assets/images/blog/blog-thum3.png';
import thumb4 from '../../assets/images/blog/blog-thum4.png';
import thumb5 from '../../assets/images/blog/blog-thum5.png';
import thumb6 from '../../assets/images/blog/blog-thum6.png';
import thumb7 from '../../assets/images/blog/blog-thum7.png';
import thumb8 from '../../assets/images/blog/blog-thum8.png';

const blogGridData = [
  {
    id: uuidv4(),
    thumb: thumb1,
    category: 'Architecture',
    postDate: 'Sept 3, 2021',
    comments: '3 comments',
    heading: 'How does the construction of a house begin',
    desc: ' All preparatory steps should be performed in a certain order. Before sta...',
    postUrl: '/single-blog',
  },

  {
    id: uuidv4(),
    thumb: thumb2,
    category: 'Architecture',
    postDate: 'Aug 10, 2021',
    comments: '3 comments',
    heading: 'Upcoming interior design trends for 2021-2022',
    desc: 'Everything is also in trend, the atmosphere remains homely, cozy and…',
    postUrl: '/single-blog',
  },

  {
    id: uuidv4(),
    thumb: thumb3,
    category: 'Facade',
    postDate: 'Aug 8, 2021',
    comments: '15 comments',
    heading: 'How does the construction of a house begin',
    desc: 'If you would like to receive a professionally prepared interior design…',
    postUrl: '/single-blog',
  },

  {
    id: uuidv4(),
    thumb: thumb4,
    category: 'Interior',
    postDate: 'Sept 3, 2021',
    comments: '3 comments',
    heading: 'Advantages of high-tech house projects',
    desc: 'All preparatory steps should be performed in a certain order. Before sta...',
    postUrl: '/single-blog',
  },

  {
    id: uuidv4(),
    thumb: thumb5,
    category: 'Landscape',
    postDate: 'Sept 3, 2021',
    comments: '3 comments',
    heading: 'Buildings with flat roofs with glass walls and parking',
    desc: 'All preparatory steps should be performed in a certain order. Before sta...',
    postUrl: '/single-blog',
  },

  {
    id: uuidv4(),
    thumb: thumb6,
    category: 'Architecture',
    postDate: ' Sept 3, 2021',
    comments: ' 3 comments',
    heading: 'How does the construction of a house begin',
    desc: 'Recently, more and more customers are choosing flat roof projects for both…',
    postUrl: '/single-blog',
  },

  {
    id: uuidv4(),
    thumb: thumb7,
    category: 'Architecture',
    postDate: ' Sept 3, 2021',
    comments: ' 3 comments',
    heading: 'How does the construction of a house begin',
    desc: ' All preparatory steps should be performed in a certain order. Before sta...',
    postUrl: '/single-blog',
  },

  {
    id: uuidv4(),
    thumb: thumb8,
    category: 'Architecture',
    postDate: ' Sept 3, 2021',
    comments: ' 3 comments',
    heading: 'How does the construction of a house begin',
    desc: ' All preparatory steps should be performed in a certain order. Before sta...',
    postUrl: '/single-blog',
  },
];

export default blogGridData;
