import React from 'react';
import cardBg1 from '../../assets/images/services/company-bg.png';
import cardBg2 from '../../assets/images/services/private-bg.png';
import InteriorCard from './InteriorCard';

function Interior() {
  return (
    <section className="interior-wrapper">
      <div className="row">
        <InteriorCard
          cardBg={cardBg1}
          shadaowHeading="Compnay"
          mainHeadidng="New Construction"
          desc="At i4 Construction Group we provide labor for wood framed multi-family projects. Our work has included hotels, multi-family apartments buildings, daycare schools… etc. We have been providing a quality product to the industry since 2000 ."
        />
        <InteriorCard
          cardBg={cardBg2}
          shadaowHeading="Private"
          mainHeadidng="Takeover as a service"
          desc="I4 construction Group is one of the largest wood framing company in USA. We have over 1400 Framers that work with us across the country. We understand that General Contractors face a common problem… framing issues, framing Delays, shortage of man power. "
          cardClr="light"
        />
      </div>
    </section>
  );
}

export default Interior;
