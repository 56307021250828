import React from 'react';

function Map() {
  return (
    <div className="contact-map-wrapper ">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d340398.22424154304!2d-81.36711515782403!3d28.538449697019047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1656114652870!5m2!1ses-419!2smx"
        className="contact-map"
        allowFullScreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </div>
  );
}

export default Map;
