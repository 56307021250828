import React from 'react';
import { Link } from 'react-router-dom';
import heroBg from '../../assets/images/illustration/line.svg';

function HeroSliderItem({
  shadowHeading,
  mainHeading,
  subHeading,
  img,
  btnText,
  btnLink,
}) {
  return (
    <div className="slider-item">
      <div className="hero-content">
        <div
          className="left-content"
          style={{ backgroundImage: `url(${heroBg})` }}
        >
          <div className="section-head">
            <span className="heading-bg">{shadowHeading}</span>
            <h2 className="section-title">
              {mainHeading}
              <span className="heading-dot" />
            </h2>
          </div>
          <p className="hero-text">{subHeading}</p>

          <div className="hero-button">
            {btnLink && (
              <Link to={btnLink} className="button button-primary">
                {btnText}
              </Link>
            )}
          </div>
        </div>
        <div
          className="right-content bg-cover"
          style={{ backgroundImage: `url(${img})` }}
        />
      </div>
    </div>
  );
}

export default HeroSliderItem;
