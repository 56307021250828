import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import banner from '../../assets/images/services/sidebar-banner-img.png';
import BrochersItem from './BrochersItem';
import SidebarLink from './SidebarLink';

function ServiceDetailsSidebar() {
  return (
    <div className="col-lg-4">
      <div className="sidebar">
        <ul className="sidebar-category">
          <SidebarLink text="Individual calculation" link="#" active />
          <SidebarLink text="Architecture Hi-Tech" link="#" />
          <SidebarLink text="Project adaptation" link="#" />
          <SidebarLink text="Landscape design" link="#" />
          <SidebarLink text=" Interior decoration" link="#" />
          <SidebarLink text=" General plan" link="#" />
        </ul>
        <div className="sidebar-banner mt-30">
          <div
            className="circle-img"
            style={{ backgroundImage: `url(${banner})` }}
          />
          <h2 className="banner-heading">
            Modern <br />
            <span className="color-heading">house</span>
          </h2>
          <div className="discount">
            <h2 className="discount-heading">50%</h2>
            <span>off</span>
          </div>
          <Link to="#" className="button button-minimal">
            Learn More
            <span className="icon">
              <i className="fas fa-arrow-right" />
              <FaArrowRight />
            </span>
          </Link>
        </div>
        <div className="sidebar-brochures">
          <div className="section-head mb-20">
            <h2 className="section-title">
              Brochures
              <span className="heading-dot" />
            </h2>
          </div>
          <BrochersItem text="Download company catalog" link="#" />
          <BrochersItem text="Download a sample project" link="#" />
        </div>
      </div>
    </div>
  );
}

export default ServiceDetailsSidebar;
