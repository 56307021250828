import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/images/portfolio/portfolio-grid1.png';
import img2 from '../../assets/images/portfolio/portfolio-grid2.png';
import img3 from '../../assets/images/portfolio/portfolio-grid3.png';
import img4 from '../../assets/images/portfolio/portfolio-grid4.png';

const portfolioGalleryData = [
  {
    id: uuidv4(),
    img: img1,
  },

  {
    id: uuidv4(),
    img: img2,
  },

  {
    id: uuidv4(),
    img: img3,
  },

  {
    id: uuidv4(),
    img: img4,
  },
];

export default portfolioGalleryData;
