import { useEffect } from 'react';

import bannerBg from '../assets/images/services/services-header.jpg';
import Interior from '../components/Interior';
import PageBanner from '../components/PageBanner';
// import Pricing from '../components/Pricing';
import ServicesSetcion from '../components/ServicesSection';

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageBanner
        shadowHeading="Services"
        mainHeading="Services"
        currentPage="Services"
        bannerBg={bannerBg}
      />
      <ServicesSetcion />
      <Interior />
      {/* <Pricing /> */}
    </>
  );
}

export default Services;
