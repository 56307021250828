import React from 'react';

function OverviewItem({ text, num }) {
  return (
    <li className="overview-item">
      <span className="item-text">{text}</span>
      <span>{num}</span>
    </li>
  );
}

export default OverviewItem;
