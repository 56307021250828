import React from 'react';
import { FaCheck } from 'react-icons/fa';

function ListItem({ listText }) {
  return (
    <li className="single-item body-text">
      <span className="single-item-icon">
        <FaCheck />
      </span>
      {listText}
    </li>
  );
}

export default ListItem;
