import React from 'react';
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/images/home/about-1.jpg';
import aboutBg from '../../assets/images/illustration/about-bg.svg';
import ListItem from './ListItem';

function About1() {
  return (
    <section
      className="about-wrapper section-padding bg-cover pb-0"
      style={{ backgroundImage: `url(${aboutBg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="left-content bg-cover">
              <img src={aboutImg} alt="men" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div className="right-content">
              <div className="section-head">
                <span className="heading-bg">About</span>
                <h2 className="section-title">
                  i4 construction Group is one of the largest wood framing
                  company in USA
                  <span className="heading-dot" />
                </h2>
              </div>
              <p className="body-text">
                i4 Construction Group works nationwide and we have the most
                qualified construction crews In quality and safety:
              </p>
              <ul className="right-content-items">
                <ListItem listText="Safety is first." />
                <ListItem listText="Build with Quality." />
                <ListItem listText="Clean job sites." />
              </ul>
              <Link to="/about" className="button button-primary">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About1;
