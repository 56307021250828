import bannerBg from '../assets/images/banner-bg.png';
import BlogGrid from '../components/BlogGrid';
import PageBanner from '../components/PageBanner';

function Blog() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="Blog"
        mainHeading="Blog"
        currentPage="Blog"
      />
      <BlogGrid />
    </>
  );
}

export default Blog;
