import React from 'react';
import { FaArrowRight, FaCalendarAlt, FaShareAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function BlogGridItem({
  thumb,
  category,
  postDate,
  comments,
  heading,
  desc,
  postUrl,
  sixCol,
}) {
  return (
    <div className={sixCol ? 'col-md-6' : 'col-lg-4 col-md-6'}>
      <div className="blog-card">
        <div
          className="card-thumb bg-cover"
          style={{ backgroundImage: `url(${thumb})` }}
        />
        <span className="category">{category}</span>
        <div className="blog-card-content">
          <ul className="blog-meta">
            <li className="meta-item">
              <span className="meta-icon">
                <i className="fal fa-calendar-alt" />
                <FaCalendarAlt />
              </span>
              {postDate}
            </li>
            <li className="meta-item comment">
              <span className="meta-icon">
                <i className="fal fa-comment-alt" />
              </span>
              {comments}
            </li>
            <li className="meta-item">
              <Link to="#" className="meta-item-link">
                <span className="meta-icon">
                  <i className="fal fa-share-alt" />
                  <FaShareAlt />
                </span>
              </Link>
            </li>
          </ul>
          <Link to={postUrl} className="blog-title">
            {heading}
          </Link>
          <p className="body-text blog-details">{desc}</p>
          <Link to={postUrl} className="button button-minimal">
            Learn More
            <span className="button-icon ml-5">
              <i className="fas fa-arrow-right" />
              <FaArrowRight />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogGridItem;
