import React from 'react';
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhone,
  FaPlus,
  // eslint-disable-next-line prettier/prettier
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import mainLogo from '../../assets/images/i4-logo.jpg';
import MobileMenu from '../MobileMenu';

function Header() {
  return (
    <header className="header-wrapper">
      <nav className="navbar">
        <div className="navbar-content d-flex">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={mainLogo} alt="i4Framing" />
            </Link>
          </div>
          <div className="menu-wrapper d-flex flex-column">
            <div className="top-menu d-flex justify-content-between align-items-center">
              <div className="top-menu-left">
                <ul className="contact-info d-flex">
                  <li className="contact-item">
                    <span className="item-icon">
                      <FaPhone />
                    </span>
                    <span className="item-text">(321) 461-0792</span>
                  </li>
                  <li className="contact-item">
                    <span className="item-icon">
                      <FaEnvelope />
                    </span>
                    <span className="item-text">contact@i4framing.com</span>
                  </li>
                  <li className="contact-item">
                    <span className="item-icon">
                      <FaMapMarkerAlt />
                    </span>
                    <span className="item-text">
                      i4 Construction Group P.O. Box 570303. Orlando, FL 32857
                    </span>
                  </li>
                </ul>
              </div>
              <div className="top-menu-right">
                <div className="social-icons">
                  <a
                    href="https://www.instagram.com/i4framing/"
                    target="_blank"
                    className="social-link"
                    rel="noreferrer"
                  >
                    <span className="social-icon">
                      <FaInstagram />
                    </span>
                  </a>
                  {/* <a href="/" className="social-link">
                    <span className="social-icon">
                      <FaTwitter />
                    </span>
                  </a> */}
                  <a
                    href="https://www.facebook.com/profile.php?id=100087386364243"
                    target="_blank"
                    className="social-link"
                    rel="noreferrer"
                  >
                    <span className="social-icon">
                      <i className="fab fa-facebook-f" />
                      <FaFacebookF />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="main-menu d-flex align-items-center justify-content-between">
              <div className="main-menu-left d-flex align-items-center">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  {/* <li>
                    <Link to="/services">Our Services</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/services-details">Service Details</Link>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <Link to="/gallery#slider">Gallery</Link>
                  </li>
                  {/* <li>
                    <Link to="/portfolio">
                      Portfolio
                      <span className="icon">
                        <FaPlus />
                      </span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/portfolio">Portfolio</Link>
                      </li>
                      <li>
                        <Link to="/portfolio-details">Portfolio Details</Link>
                      </li>
                    </ul>
                  </li> */}

                  {/* <li>
                    <Link to="/blog">
                      Blog
                      <span className="icon">
                        <FaPlus />
                      </span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/blog">Blog Grid</Link>
                      </li>
                      <li>
                        <Link to="/single-blog">Single post</Link>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <Link to="/contact">
                      We are hiring
                      <span className="icon">
                        <FaPlus />
                      </span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/contact">
                          Build a better future and become a member of i4
                          construction Group.
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="main-menu-right d-flex align-items-center">
                {/* <span className="menu-right-search">
                  <FaSearch onClick={searchHanlder} />
                </span>
                <div className={showSearch ? 'search-box' : 'search-box show'}>
                  <div className="search-input-wrapper d-flex">
                    <input type="text" placeholder="Search here..." />
                    <button type="button" className="search-button">
                      <FaSearch />
                    </button>
                  </div>
                </div> */}
                <div className="headerNextProject">
                  <span>For your next project</span>
                  <Link to="/contact">
                    <button type="button" className="button button-primary">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <MobileMenu />
    </header>
  );
}

export default Header;
