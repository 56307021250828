import React from 'react';
import { Accordion } from 'react-bootstrap';
import icon2 from '../../assets/images/icons/bank.svg';
import icon1 from '../../assets/images/icons/bulb.svg';
import icon3 from '../../assets/images/icons/hammer.svg';
import thumb1 from '../../assets/images/services/service-details-thumb.png';
import ServiceDetailsCard from './ServiceDetailsCard';
import ServiceDetailsSidebar from './ServiceDetailsSidebar';

function ServiceDetails() {
  return (
    <div className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row gy-5 gy-lg-0">
          <div className="col-lg-8">
            <div className="main-content">
              <h2 className="heading-2">Create an individual home project?</h2>
              <p className="body-text mt-25 mb-25">
                An ideal country house project is a house where spacious and
                warm rooms, in which there is a lot of light and air for you and
                your children, and where all your desires are realized in the
                smallest detail.
              </p>
              <p className="body-text mb-25">
                An ideal project does not start with a foundation, but with very
                specific desires that you have dreamed of all your life. Some
                dream of an Alpine-style house and their own wine cellar, others
                of a spacious sauna with a swimming pool or of where to place
                numerous and long-stay friends, while others of walking from the
                terrace onto the fresh grass and drinking tea while admiring sea
                through the transparent walls of the living room. But not
                everyone can clearly formulate which terms he needs.
              </p>
              <div
                className="content-thumb bg-cover"
                style={{ backgroundImage: `url(${thumb1})` }}
              />
              <p className="body-text mt-50">
                Our architects will help your family find the formula for an
                ideal country house that will be 100% consistent with your
                lifestyle, ideas about a comfortable country life, the concept
                of resource conservation and optimally unleash the potential of
                the selected site. What do I need from you? Try to determine the
                general style, construction technology and describe your desires
                in as much detail as possible. The rest is our job.
              </p>

              {/* Accordion */}
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Why do you need a plan?</Accordion.Header>
                  <Accordion.Body>
                    The development of the general plan of the site is the
                    placement of the future house on the site relative to other
                    buildings, neighboring plots, environmental zones, taking
                    into account insolation.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Features of the burn house style?
                  </Accordion.Header>
                  <Accordion.Body>
                    The development of the general plan of the site is the
                    placement of the future house on the site relative to other
                    buildings, neighboring plots, environmental zones, taking
                    into account insolation.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    What is the scandivian style?
                  </Accordion.Header>
                  <Accordion.Body>
                    The development of the general plan of the site is the
                    placement of the future house on the site relative to other
                    buildings, neighboring plots, environmental zones, taking
                    into account insolation.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <h2 className="heading-2 mt-60 mb-20">
                Create an individual home project?
              </h2>
              <p className="body-text">
                We, a team of professional experienced architects, designers and
                engineers, have been helping our clients for over 20 years to
                find a unique and unique in nature formula for their ideal
                country cottage, where, step by step, a family history is
                created, children are born, family dinners and meetings with
                friends are held. Our tasks:
              </p>
              <div className="row">
                <ServiceDetailsCard
                  icon={icon1}
                  heading="Understand ideas"
                  text=" Understand your stylistic ideas and architectural."
                />
                <ServiceDetailsCard
                  icon={icon2}
                  heading="Design a house"
                  text=" Your home design will be perfect for you."
                />
                <ServiceDetailsCard
                  icon={icon3}
                  heading="Consider all laws"
                  text=" Consider all laws of the organization."
                />
              </div>
              <p className="body-text mt-40">
                Design is an obligatory part of the construction of a country
                house. It is here that the foundation for the safety, strength
                and durability of the structure is laid. How carefully the
                design project of the house will be worked out, so much it will
                be reliable in operation. The designer, in accordance with the
                architectural project, calculates the loads, connections,
                selects the optimal materials and sections of structural
                elements of the foundation, walls and roof.
              </p>
            </div>
          </div>
          <ServiceDetailsSidebar />
        </div>
      </div>
    </div>
  );
}

export default ServiceDetails;
