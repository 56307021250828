import { v4 as uuidv4 } from 'uuid';

const ServicesCardData = [
  {
    id: uuidv4(),
    heading: 'Multi-family Buildings',
    text: '',
    background: '',
  },

  {
    id: uuidv4(),
    heading: 'Student Housing',
    text: '',
    background: 'white',
  },

  {
    id: uuidv4(),
    heading: 'Assisted Living',
    text: '',
    background: 'white',
  },

  {
    id: uuidv4(),
    heading: 'Hotels',
    text: '',
    background: '',
  },

  {
    id: uuidv4(),
    heading: 'Take Over as a Service',
    text: '',
    background: 'white',
  },

  // {
  //   id: uuidv4(),
  //   heading: 'Project adaption',
  //   text: 'Adaptation of the finished project from the site.',
  //   background: '',
  // },
];

export default ServicesCardData;
