const menuData = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'About Us',
    path: '/about',
  },
  // {
  //   title: 'Services',
  //   path: '/services',
  // },
  {
    title: 'Gallery',
    path: '/gallery',
  },
  {
    title: 'Contact',
    path: '/contact',
  },
];

export default menuData;
