import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function WidgetAuthor({ img, name, desig, insta, fb, twitter }) {
  return (
    <div className="widget-author">
      <div
        className="author-avatar bg-cover"
        style={{ backgroundImage: `url(${img})` }}
      />
      <Link to="/" className="author-name heading-2">
        {name}
      </Link>
      <span className="author-role">{desig}</span>
      <div className="author-socials">
        <Link to={insta} className="social-item">
          <span className="social-icon">
            <FaInstagram />
          </span>
        </Link>
        <Link to={twitter} className="social-item">
          <span className="social-icon">
            <FaTwitter />
          </span>
        </Link>
        <Link to={fb} className="social-item">
          <span className="social-icon">
            <FaFacebook />
          </span>
        </Link>
      </div>
    </div>
  );
}

export default WidgetAuthor;
