import bannerBg from '../assets/images/banner-bg.png';
import PageBanner from '../components/PageBanner';
import ServiceDetails from '../components/ServiceDetails';

function ServicesDetails() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="Services"
        mainHeading="Services"
        currentPage="Services Details"
        nasted
        nastedPage="Services"
        nastedLink="/services"
      />
      <ServiceDetails />
    </>
  );
}

export default ServicesDetails;
