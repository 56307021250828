import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import testimonialBg from '../../assets/images/testimonial-illustration.png';
import galleryData from './galleryData';
import GalleryItem from './GalleryItem';

SwiperCore.use([Navigation]);

function GallerySlider() {
  return (
    <div
      className="testimonial-wrapper section-padding"
      style={{ backgroundImage: `url(${testimonialBg})` }}
      id="slider"
    >
      <div className="container">
        <div className="section-head">
          <span className="heading-bg">Gallery</span>
          <h2 className="section-title">
            Gallery
            <span className="heading-dot" />
          </h2>
        </div>
        <div className="testimonial-slider-wrapper">
          <div className="testimonial-slider ">
            <Swiper autoPlay loop navigation>
              {galleryData.map((item) => (
                <SwiperSlide key={item.id}>
                  <GalleryItem
                    heading={item.heading}
                    desc={item.desc}
                    clientName={item.clientName}
                    clientRole={item.clientRole}
                    thumb={item.thumb}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GallerySlider;
