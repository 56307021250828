import React from 'react';
import { Link } from 'react-router-dom';
import ctaBg from '../../assets/images/footer-cta-bg.png';

function Cta() {
  return (
    <div className="footer-cta" style={{ backgroundImage: `url(${ctaBg})` }}>
      <div className="cta-content d-flex align-items-center justify-content-between flex-wrap flex-lg-nowrap">
        <div className="content-left">
          <h3 className="heading-2 white">For your next project...</h3>
          <p className="body-text">
            We are the Framing contractor you need. Call us.
          </p>
        </div>
        <div className="content-right">
          <Link to="/contact" className="button button-primary">
            Contact us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Cta;
