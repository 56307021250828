import React from 'react';

function WidgetItem({ linkText, linkUrl }) {
  return (
    <li className="widget-item">
      <a href={linkUrl} className="widget-link">
        {linkText}
      </a>
    </li>
  );
}

export default WidgetItem;
