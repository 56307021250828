import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import RecentProject from './RecentProject';
import RecentProjectData from './RecentProjectData';

function RecentProjects() {
  return (
    <section className="recent-project-wrapper section-padding">
      <div className="container">
        <div className="section-head text-center mb-30">
          <span className="heading-bg">cases</span>
          <h2 className="section-title">
            Explore recent projects
            <span className="heading-dot" />
          </h2>
        </div>
        <SRLWrapper>
          <div className="row gy-4 gy-lg-0 mt-50">
            {RecentProjectData.map((data) => (
              <RecentProject key={data.id} img={data.img} />
            ))}
          </div>
        </SRLWrapper>
      </div>
    </section>
  );
}

export default RecentProjects;
