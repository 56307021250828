import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import author from '../../assets/images/blog/blog-author.png';
import thumb1 from '../../assets/images/blog/recent-post-thumb1.png';
import thumb2 from '../../assets/images/blog/recent-post-thumb2.png';
import thumb3 from '../../assets/images/blog/recent-post-thumb3.png';
import galleryImg1 from '../../assets/images/portfolio/portfolio-item1.png';
import galleryImg2 from '../../assets/images/portfolio/portfolio-item2.png';
import galleryImg3 from '../../assets/images/portfolio/portfolio-item3.png';
import galleryImg5 from '../../assets/images/portfolio/portfolio-item5.png';
import galleryImg6 from '../../assets/images/portfolio/portfolio-item6.png';
import Tags from '../SinglePost/Tags';
import WidgetAuthor from './WidgetAuthor';
import WidgetRecentPost from './WidgetRecentPost';
import WidgetSearch from './WidgetSearch';

function BlogSidebar() {
  return (
    <div className="col-lg-4">
      <div className="single-post-sidebar">
        <WidgetSearch />
        <WidgetAuthor
          img={author}
          name="Rosalie Martinez"
          desig="Blog author"
          fb="/"
          twitter="/"
          insta="/"
        />

        <div className="widget-recent-post">
          <div className="section-head">
            <h2 className="section-title">
              Recent post
              <span className="heading-dot" />
            </h2>
          </div>
          <div className="recent-posts">
            <WidgetRecentPost
              thumb={thumb1}
              title=" How the Victorian fashion came to be"
              meta="Sep 28, 2021 "
              postLink="#"
            />
            <WidgetRecentPost
              thumb={thumb2}
              title="  How to read an architectural project"
              meta="Sep 28, 2021 "
              postLink="#"
            />
            <WidgetRecentPost
              thumb={thumb3}
              title="   Design rules for business centers"
              meta="Sep 28, 2021 "
              postLink="#"
            />
          </div>
        </div>
        <div className="widget-tags">
          <div className="section-head">
            <h2 className="section-title">
              Tags
              <span className="heading-dot" />
            </h2>
          </div>
          <ul className="post-tags">
            <li className="tags-item">Tags:</li>
            <Tags tag="#Modern" link="#" />
            <Tags tag="#Rofs" link="#" />
            <Tags tag="#Hi-tech" link="#" />
            <Tags tag="#Skyscaper" link="#" />
            <Tags tag="#Design" link="#" />
            <Tags tag="#Building" link="#" />
            <Tags tag="#Construction" link="#" />
          </ul>
        </div>

        <div className="widget-gallery">
          <SRLWrapper>
            <div className="section-head">
              <h2 className="section-title">
                Gallery
                <span className="heading-dot" />
              </h2>
            </div>
            <div className="row gy-2">
              <div className="col-4">
                <a href={galleryImg1} className="gallery-item">
                  <img src={galleryImg1} alt="" />
                </a>
              </div>
              <div className="col-4">
                <a f={galleryImg2} className="gallery-item">
                  <img src={galleryImg2} alt="" />
                </a>
              </div>
              <div className="col-4">
                <a f={galleryImg3} className="gallery-item">
                  <img src={galleryImg3} alt="" />
                </a>
              </div>
              <div className="col-4">
                <a f={galleryImg2} className="gallery-item">
                  <img src={galleryImg2} alt="" />
                </a>
              </div>
              <div className="col-4">
                <a f={galleryImg5} className="gallery-item">
                  <img src={galleryImg5} alt="" />
                </a>
              </div>
              <div className="col-4">
                <a f={galleryImg6} className="gallery-item">
                  <img src={galleryImg6} alt="" />
                </a>
              </div>
            </div>
          </SRLWrapper>
        </div>
      </div>
    </div>
  );
}

export default BlogSidebar;
