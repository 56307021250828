import React from 'react';
import { Link } from 'react-router-dom';
import blogGridData from './blogGridData';
import BlogGridItem from './BlogGridItem';

function BlogGrid() {
  return (
    <section className="blog-card-wrapper section-padding">
      <div className="container">
        <div className="row gy-4 gy-4">
          {blogGridData.map((data) => (
            <BlogGridItem
              key={data.id}
              thumb={data.thumb}
              category={data.category}
              postDate={data.postDate}
              comments={data.comments}
              heading={data.heading}
              desc={data.desc}
              postUrl={data.postUrl}
            />
          ))}
        </div>
        <div className="blog-pagination-wrapper">
          <ul className="blog-pagination">
            <li className="pagination-item">
              <Link to="#" className="pagination-link active">
                1
              </Link>
            </li>
            <li className="pagination-item">
              <Link to="#" className="pagination-link">
                2
              </Link>
            </li>
            <li className="pagination-item">
              <Link to="#" className="pagination-link">
                3
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default BlogGrid;
