import { useEffect } from 'react';

import bannerBg from '../assets/images/contact/map.jpg';
import ContactSection from '../components/ContactSection';
import Map from '../components/Map/Map';
import PageBanner from '../components/PageBanner';

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="i4Framing"
        mainHeading="Contact us"
        currentPage="Contact"
      />
      <ContactSection />
      <Map />
    </>
  );
}

export default Contact;
