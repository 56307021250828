import React from 'react';

function OwnerCard({ avatar, name, designation, phone, email, marginTop }) {
  return (
    <div className={marginTop ? 'profile-item mt-40' : 'profile-item'}>
      <div
        className="profile-avatar bg-cover"
        style={{ backgroundImage: `url(${avatar})` }}
      />
      <div className="profile-details">
        <h3 className="name">{name}</h3>
        <span className="role body-text">{designation}</span>
        <ul className="contact-medium mt-10">
          <li className="medium-item">{phone}</li>
          <li className="medium-item">{email}</li>
        </ul>
      </div>
    </div>
  );
}

export default OwnerCard;
