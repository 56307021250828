import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SRLWrapper } from 'simple-react-lightbox';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import sidebarImg from '../../assets/images/portfolio/building-img.png';
import sliderImg1 from '../../assets/images/portfolio/single-portfolio-slider1.png';
import OverviewItem from './OverviewItem';
import PortfolioGalleryItem from './PortfolioGallery';
import portfolioGalleryData from './portfolioGalleryData';
import RecentProjects from './RecentProjects';

SwiperCore.use([Navigation]);

function SinglePortfolio() {
  return (
    <>
      <section className="project-details-wrapper section-padding">
        <div className="container">
          <Swiper navigation loop className="project-details-slider">
            <SwiperSlide className="slider-item">
              <div
                className="slider-img bg-cover"
                style={{ backgroundImage: `url(${sliderImg1})` }}
              />
            </SwiperSlide>
            <SwiperSlide className="slider-item">
              <div
                className="slider-img bg-cover"
                style={{ backgroundImage: `url(${sliderImg1})` }}
              />
            </SwiperSlide>
            <SwiperSlide className="slider-item">
              <div
                className="slider-img bg-cover"
                style={{ backgroundImage: `url(${sliderImg1})` }}
              />
            </SwiperSlide>
          </Swiper>

          <div className="project-details-content">
            <div className="row gy-5 gy-lg-0">
              <div className="col-lg-6">
                <div className="content-left">
                  <h3 className="heading-2 mb-30">Project-details</h3>
                  <p className="body-text">
                    The project presents a detailed two-storey house in which
                    one family can live comfortably throughout the year. A
                    successful exterior design (high-tech) and a
                    well-thought-out internal arrangement make the houses built
                    according to this project will always be visible objects in
                    the suburban real estate market. The garage for one car is
                    designed as a one-storey attached block. There is a spacious
                    terrace on its roof.
                  </p>

                  <div className="overview-wrapper mt-60">
                    <h3 className="heading-2 overview-heading">
                      Overview & solutons
                    </h3>
                    <p className="body-text mt-25">
                      The first floor of the cottage is an area for the daily
                      needs of all residents of the house. A comfortable living
                      room is combined in one space with a kitchen-dining room.
                    </p>
                    <ul className="overview-items mt-20">
                      <OverviewItem text="Total area" num="125m" />
                      <OverviewItem text="Floor" num="2" />
                      <OverviewItem text="Bedrooms" num="5" />
                      <OverviewItem text="Bathrooms" num="3" />
                      <OverviewItem text="Garage" num="2" />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1">
                <div className="right-content">
                  <div className="project-info">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="info-box box-1">
                          <span className="top-text body-text">Client</span>
                          <h4 className="info-heading">Benjamin Henderson</h4>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="info-box box-2">
                          <span className="top-text body-text">Category</span>
                          <h4 className="info-heading">Interior decoration</h4>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="info-box box-3">
                          <span className="top-text body-text">Lasting</span>
                          <h4 className="info-heading">35 days</h4>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="info-box box-4">
                          <span className="top-text body-text">Price</span>
                          <h4 className="info-heading">$14 500</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-img mt-80">
                    <img src={sidebarImg} alt="building" />
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-gallery mt-50">
              <SRLWrapper>
                <div className="row gy-4">
                  {portfolioGalleryData.map((data) => (
                    <PortfolioGalleryItem img={data.img} key={data.id} />
                  ))}
                </div>
              </SRLWrapper>
            </div>
          </div>
          <div className="project-details-nav">
            <div className="nav-box">
              <Link to="" className="nav-prev nav-action mr-20">
                <span className="nav-icon">
                  <FaChevronLeft />
                </span>
              </Link>
              <Link to="" className="nav-text d-none d-sm-block">
                Glass office building with <br />
                three floors
              </Link>
            </div>
            <div className="nav-box">
              <Link to="" className="nav-text mr-20 d-none d-sm-block">
                House with flat roof and <br />
                attached garage
              </Link>
              <Link to="" className="nav-prev nav-action">
                <span className="nav-icon">
                  <FaChevronRight />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <RecentProjects />
    </>
  );
}

export default SinglePortfolio;
