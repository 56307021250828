import { v4 as uuidv4 } from 'uuid';
import brand1 from '../../assets/images/icons/brands/brand1.jpg';
import brand2 from '../../assets/images/icons/brands/brand2.png';
import brand3 from '../../assets/images/icons/brands/brand3.png';
import brand4 from '../../assets/images/icons/brands/brand4.jpg';
import brand5 from '../../assets/images/icons/brands/brand5.jpg';
import brand6 from '../../assets/images/icons/brands/brand6.jpg';
import brand7 from '../../assets/images/icons/brands/brand7.jpg';
import brand8 from '../../assets/images/icons/brands/brand8.jpg';

const brandItemData = [
  {
    id: uuidv4(),
    brand: brand1,
    link: 'https://www.sunbeltrentals.com',
  },
  {
    id: uuidv4(),
    brand: brand2,
    link: 'https://www.matheuslumber.com/',
  },
  {
    id: uuidv4(),
    brand: brand3,
    link: 'https://framerscouncil.org/',
  },
  {
    id: uuidv4(),
    brand: brand4,
    link: 'https://www.bridgewellresources.com/',
  },
  {
    id: uuidv4(),
    brand: brand5,
    link: 'https://www.strongtie.com/',
  },
  {
    id: uuidv4(),
    brand: brand6,
    link: 'https://threadedfasteners.com/',
  },
  {
    id: uuidv4(),
    brand: brand7,
    link: 'https://www.metabo-hpt.com/',
  },
  {
    id: uuidv4(),
    brand: brand8,
    link: 'https://www.bldr.com/',
  },
];

export default brandItemData;
