import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/images/portfolio/portfolio-project1.png';
import img2 from '../../assets/images/portfolio/portfolio-project2.png';
import img3 from '../../assets/images/portfolio/portfolio-project3.png';

const RecentProjectData = [
  {
    id: uuidv4(),
    img: img1,
  },

  {
    id: uuidv4(),
    img: img2,
  },

  {
    id: uuidv4(),
    img: img3,
  },
];

export default RecentProjectData;
