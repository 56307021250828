import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SRLWrapper } from 'simple-react-lightbox';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import project1 from '../../assets/images/portfolio/project-1.png';
import project2 from '../../assets/images/portfolio/project-2.png';
import project3 from '../../assets/images/portfolio/project-3.png';
import project4 from '../../assets/images/portfolio/project-4.png';
import project5 from '../../assets/images/portfolio/project-5.png';
import project6 from '../../assets/images/portfolio/project-6.png';
import BrandItem from './BrandItem';
import brandItemData from './brandItemData';

function Projects() {
  return (
    <div className="project-wrapper pb-100">
      <div className="project-top">
        <div className="container">
          <div className="top-content d-flex justify-content-between align-items-end flex-wrap">
            <div className="section-head">
              <span className="heading-bg">Cases</span>
              <h2 className="section-title mb-20">
                Our Partners
                <span className="heading-dot" />
              </h2>
            </div>
            <div className="top-right">
              {/* <Link to="/portfolio" className="button button-primary">
                See all projects
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="project-cards project-card-gallery">
        {false && (
          <SRLWrapper>
            <div className="row">
              <div className="col-md-6">
                <div href={project1} className="project-card">
                  <a href={project1}>
                    <img className="project-card-img" src={project1} alt="" />
                  </a>
                  <span className="card-plus-icon">
                    <FaPlus />
                  </span>
                  <div className="card-content">
                    <h3>
                      <Link to="/" className="title">
                        Modern Skyscraper
                      </Link>
                    </h3>
                    <span className="text">Project Adaption</span>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="project-card">
                      <a href={project2}>
                        <img
                          className="project-card-img"
                          src={project2}
                          alt=""
                        />
                      </a>
                      <span className="card-plus-icon">
                        <FaPlus />
                      </span>
                      <div className="card-content">
                        <h3>
                          <Link to="/" className="title">
                            Modern Skyscraper
                          </Link>
                        </h3>
                        <span className="text">Project Adaption</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div href={project3} className="project-card">
                      <a href={project3}>
                        <img
                          className="project-card-img"
                          src={project3}
                          alt=""
                        />
                      </a>
                      <span className="card-plus-icon">
                        <FaPlus />
                      </span>
                      <div className="card-content">
                        <h3>
                          <Link to="/" className="title">
                            Modern Skyscraper
                          </Link>
                        </h3>
                        <span className="text">Project Adaption</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="project-card">
                      <a href={project4}>
                        <img
                          className="project-card-img"
                          src={project4}
                          alt=""
                        />
                      </a>
                      <span className="card-plus-icon">
                        <FaPlus />
                      </span>
                      <div className="card-content">
                        <h3>
                          <Link to="/" className="title">
                            Modern Skyscraper
                          </Link>
                        </h3>
                        <span className="text">Project Adaption</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="project-card">
                      <a href={project5}>
                        <img
                          className="project-card-img"
                          src={project5}
                          alt=""
                        />
                      </a>
                      <span className="card-plus-icon">
                        <FaPlus />
                      </span>
                      <div className="card-content">
                        <h3>
                          <Link to="/" className="title">
                            Modern Skyscraper
                          </Link>
                        </h3>
                        <span className="text">Project Adaption</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="project-card">
                  <a href={project6}>
                    <img className="project-card-img" src={project6} alt="" />
                  </a>
                  <span className="card-plus-icon">
                    <FaPlus />
                  </span>
                  <div className="card-content">
                    <h3>
                      <Link to="/" className="title">
                        Modern Skyscraper
                      </Link>
                    </h3>
                    <span className="text">Project Adaption</span>
                  </div>
                </div>
              </div>
            </div>
          </SRLWrapper>
        )}
      </div>
      <div className="project-brands mb-100">
        <div className="project-brand-slider d-flex justify-content-lg-between align-items-center">
          <Swiper
            slidesPerView={1}
            loop
            spaceBetween={40}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              576: {
                slidesPerView: 2,
              },

              768: {
                slidesPerView: 3,
              },

              992: {
                slidesPerView: 6,
              },
            }}
          >
            {brandItemData.map((item) => (
              <SwiperSlide key={item.id}>
                <BrandItem brand={item.brand} link={item.link} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Projects;
