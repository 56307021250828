import React from 'react';

function PortfolioGalleryItem({ img }) {
  return (
    <div className="col-md-6 project-details-gallery-items">
      <a href={img} className="gallery-item">
        <img src={img} alt="" className="item-img" />
      </a>
    </div>
  );
}

export default PortfolioGalleryItem;
