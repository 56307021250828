import React from 'react';
import { FaReply } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function CommentBox({ replay, avatar, meta, name, comment }) {
  return (
    <div className={replay ? 'comment-box comment-reply' : 'comment-box'}>
      <div className="box-top">
        <div className="comment-profile">
          <Link to="/">
            <div
              className="avatar bg-cover"
              style={{ backgroundImage: `url(${avatar})` }}
            />
          </Link>
          <div className="info">
            <h4 className="name">{name}</h4>
            <span className="comment-meta">{meta}</span>
          </div>
        </div>
        <Link to="/" className="comment-reply-btn">
          <span className="reply-icon">
            <FaReply />
          </span>
          <span className="reply-text">Reply</span>
        </Link>
      </div>
      <p className="body-text comment-text">{comment}</p>
    </div>
  );
}

export default CommentBox;
