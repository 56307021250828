import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import ScrollTopBtn from './components/ScrollTopBtn';
import About from './pages/About';
import Blog from './pages/Blog';
import SingleBlog from './pages/BlogDetails';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Gallery from './pages/Gallery';
import PortfolioDetails from './pages/PortfolioDetails';
import Services from './pages/Services';
import ServicesDetails from './pages/ServicesDetails';

function App() {
  return (
    <>
      <ScrollTopBtn />
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} on />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services-details" element={<ServicesDetails />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio-details" element={<PortfolioDetails />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/single-blog" element={<SingleBlog />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
