import React from 'react';
import { Link } from 'react-router-dom';

function Tags({ tag, link }) {
  return (
    <li className="tags-item">
      <Link to={link} className="tags-link">
        {tag}
      </Link>
    </li>
  );
}

export default Tags;
