import { v4 as uuidv4 } from 'uuid';
import icon1 from '../../assets/images/icons/medal.png';
import icon4 from '../../assets/images/icons/recycle.png';
import icon2 from '../../assets/images/icons/shield.png';
import icon3 from '../../assets/images/icons/thumb-up.png';

const whyUsData = [
  {
    id: uuidv4(),
    icon: icon2,
    heading: 'Safety',
    text: 'Safety is first, we have safety officers in charge of the constant training of our team members, We do regular job sites inspections to ensure that safety rules are followed correctly.',
  },
  {
    id: uuidv4(),
    icon: icon1,
    heading: 'Quality assurance',
    text: 'Pre-construction analysis is the key to build right the first time, we have more that 22 years Of experience in wood frame construction and management.',
  },
  {
    id: uuidv4(),
    icon: icon3,
    heading: 'Clean Job Sites',
    text: 'A project can be built with high standards, but if cleanliness is not important, it loses value. At i4 Construction Group we place emphasis on keeping our projects clean and organized materials.',
  },
  {
    id: uuidv4(),
    icon: icon4,
    heading: 'Recycle',
    text: 'We keep in mind the importance for our customers the correct use of materials.  that’s why our priority is the recycling of materials and the minimum of waste.',
  },
];

export default whyUsData;
