import React from 'react';
import avatar1 from '../../assets/images/contact/envelope.jpg';
import ContactForm from './ContactForm';
import OwnerCard from './OwnerCard';

function ContactSection() {
  return (
    <section className="contact-wrapper section-padding">
      <div className="container">
        <div className="row align-items-center gy-5 gy-lg-0">
          <div className="col-lg-6">
            <div className="left-content">
              <div className="section-head">
                <span className="heading-bg">Contact</span>
                <h2 className="section-title">
                  Get in touch
                  <span className="heading-dot" />
                </h2>
                <p className="body-text mt-20 mb-30">
                  After you submit this form, we will contact you as soon as
                  possible.
                </p>
              </div>
              <div className="contact-profile">
                <OwnerCard
                  avatar={avatar1}
                  name="Enrique Paredes"
                  designation="VP Field  Operations "
                  phone="(321) 461-0792"
                  email="contact@i4framing.com"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="right-content">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
