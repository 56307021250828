import React from 'react';
import whyUsData from './whyUsData';
import WhyUsItem from './WhyUsItem';

function WhyUs() {
  return (
    <section className="why-us-wrapper section-padding">
      <div className="container">
        <div className="row gy-6 gy-5 gy-lg-0">
          <div className="col-lg-6 d-flex align-items-center">
            <div className="left-content">
              <div className="section-head">
                <span className="heading-bg">Why us</span>
                <h2 className="section-title mb-30">
                  Safety, Quality & Clean Job Sites
                  <span className="heading-dot" />
                </h2>
              </div>
              <p className="body-text mb-20">
                We have been providing a quality product to the industry since
                2000.
              </p>
              <p className="body-text">
                i4 Construction Group works nationwide and we have the most
                qualified construction crews In quality and safety.
              </p>
              {/* <Link to="/" className="button button-primary mt-35">
                Learn More
              </Link> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="right-content">
              <div className="row">
                {whyUsData.map((data) => (
                  <WhyUsItem
                    key={data.id}
                    icon={data.icon}
                    heading={data.heading}
                    text={data.text}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyUs;
