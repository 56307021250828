import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/images/gallery/gallery1.jpg';
import thumb2 from '../../assets/images/gallery/gallery2.jpg';
import thumb3 from '../../assets/images/gallery/gallery3.jpg';
import thumb4 from '../../assets/images/gallery/gallery4.jpg';
import thumb5 from '../../assets/images/gallery/gallery5.jpg';
import thumb6 from '../../assets/images/gallery/gallery6.jpg';
import thumb7 from '../../assets/images/gallery/gallery7.jpg';
import thumb8 from '../../assets/images/gallery/gallery8.jpg';
import thumb9 from '../../assets/images/gallery/gallery9.jpg';

const galleryData = [
  {
    id: uuidv4(),
    thumb: thumb1,
  },

  {
    id: uuidv4(),
    thumb: thumb2,
  },
  {
    id: uuidv4(),
    thumb: thumb3,
  },
  {
    id: uuidv4(),
    thumb: thumb4,
  },
  {
    id: uuidv4(),
    thumb: thumb5,
  },
  {
    id: uuidv4(),
    thumb: thumb6,
  },
  {
    id: uuidv4(),
    thumb: thumb7,
  },
  {
    id: uuidv4(),
    thumb: thumb8,
  },
  {
    id: uuidv4(),
    thumb: thumb9,
  },
];

export default galleryData;
