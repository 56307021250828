import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

function WidgetSearch() {
  const [search, setSearch] = useState('');
  const handleSearch = (e) => {
    e.preventDefault();
  };
  return (
    <div className="widget-search">
      <input
        type="text"
        placeholder="Search..."
        className="search-field"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <button
        type="button"
        className="button search-button"
        onClick={handleSearch}
      >
        <span className="search-icon">
          <FaSearch />
        </span>
      </button>
    </div>
  );
}

export default WidgetSearch;
