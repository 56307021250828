import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/images/home/hero-1.jpg';
import img2 from '../../assets/images/home/hero-2.jpg';

const heroSliderData = [
  {
    id: uuidv4(),
    shadowHeading: 'i4Framing',
    mainHeading: 'we provide labor for wood framed multi-family projects.',
    subHeading:
      'Our work has included hotels, multi-family apartments buildings, daycare schools…',
    img: img1,
    btnText: 'Learn More',
    btnLink: '',
  },

  {
    id: uuidv4(),
    shadowHeading: 'i4Framing',
    mainHeading: 'Providing a quality product to the industry since 2000',
    subHeading:
      'i4 Construction Group works nationwide and we have the most qualified construction crews In quality and safety.',
    img: img2,
    btnText: 'Learn More',
    btnLink: '',
  },
];

export default heroSliderData;
