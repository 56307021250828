import { useState } from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaPaperPlane,
  // eslint-disable-next-line prettier/prettier
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import emailIcon from '../../assets/images/icons/email.svg';
import mapIcon from '../../assets/images/icons/map-point.svg';
import phoneIcon from '../../assets/images/icons/phone-call.svg';
import mainLogo from '../../assets/images/i4-logo.jpg';
import Cta from './Cta';
import FooterContact from './FooterContact';
import WidgetItem from './WidgetItem';

function Footer() {
  const [input, setInput] = useState('');

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <footer className="footer-wrapper">
      <div className="container">
        <Cta />
        <div className="row gy-5 gy-lg-0">
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget widget-about">
              <Link to="/" className="footer-logo">
                <img src={mainLogo} alt="Credom" />
              </Link>
              <p className="body-text light">
                Our range of services is very large, and we are constantly
                expanding it. More than two decades of expertise are designed to
                performance.
              </p>
              <div className="widget-about-social">
                <a
                  href="https://www.instagram.com/i4framing/"
                  target="_blank"
                  className="social-link"
                  rel="noreferrer"
                >
                  <span className="social-icon">
                    <FaInstagram />
                  </span>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100087386364243"
                  target="_blank"
                  className="social-link"
                  rel="noreferrer"
                >
                  <span className="social-icon">
                    <FaFacebookF />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
            <div className="footer-widget widget-services">
              <h3 className="widget-heading">Our Services</h3>
              <ul className="widget-menu">
                <WidgetItem
                  linkUrl="/#services"
                  linkText="Multi-family Buildings"
                />
                <WidgetItem linkUrl="/#services" linkText="Student Housing" />
                <WidgetItem linkUrl="/#services" linkText="Assisted Living" />
                <WidgetItem linkUrl="/#services" linkText="Hotels" />
                <WidgetItem
                  linkUrl="/#services"
                  linkText="Takeover as a service"
                />
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
            <div className="footer-widget widget-useful-links">
              <h3 className="widget-heading">Useful links</h3>
              <ul className="widget-menu">
                <WidgetItem linkUrl="/about" linkText="About us" />
                <WidgetItem linkUrl="/gallery" linkText="Gallery" />
                <WidgetItem linkUrl="/contact" linkText="Contact us" />
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget widget-subscribe">
              <h3 className="widget-heading">Subscribe</h3>
              <p className="body-text light">
                Subscribe to our newsletter! Stay always in touch!
              </p>
              <form action="#" className="subscribe-form">
                <div className="form-input-wrapper">
                  <input
                    type="text"
                    placeholder="Your Email"
                    className="form-input"
                    value={input}
                    onChange={handleChange}
                  />
                  <button
                    type="submit"
                    className="button subscribe-button"
                    onSubmit={handleSubmit}
                  >
                    <span className="button-icon">
                      <FaPaperPlane />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-contact-wrapper">
          <div className="row">
            <FooterContact
              icon={phoneIcon}
              howToContact="Call Us"
              text="+1 (321) 461-0792"
            />
            <FooterContact
              icon={emailIcon}
              howToContact="Write to us"
              text="contact@i4framing.com"
            />
            <div className="col-lg-4">
              <div
                className="footer-contact d-flex align-items-center"
                style={{ borderRight: 'none' }}
              >
                <span className="contact-icon">
                  <img src={mapIcon} alt="map" />
                </span>
                <div className="contact-info">
                  <span className="top-text">Our location</span>
                  <p className="main-text">
                    P.O. Box 570303. Orlando, FL 32857
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-text text-center">
          <p className="body-text light">&copy; 2022 All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
