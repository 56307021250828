import React from 'react';
import ServicesCard from './ServicesCard';
import ServicesCardData from './ServicesCardData';

function ServicesSection() {
  return (
    <section className="services-wrapper section-padding" id="services">
      <div className="container">
        <div className="row mb-80">
          <div className="col-lg-6 p-2">
            <div className="section-head mt-40">
              <span className="heading-bg">Offers</span>
              <h2 className="section-title">
                We provide labor for wood framed multi-family projects.
                <span className="heading-dot" />
              </h2>
            </div>
            <p className="body-text padding-right">
              Our work has included hotels, multi-family apartments buildings,
              daycare schools
            </p>
          </div>

          {ServicesCardData.map((item) => (
            <ServicesCard
              key={item.id}
              heading={item.heading}
              text={item.text}
              background={item.background}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
