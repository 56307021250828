import React from 'react';

function WhyUsItem({ icon, heading, text }) {
  return (
    <div className="col-md-6">
      <div className="right-card">
        <img src={icon} alt="" />
        <h3 className="card-heading">{heading}</h3>
        <div className="body-text">{text}</div>
      </div>
    </div>
  );
}

export default WhyUsItem;
