import React from 'react';
import { Link } from 'react-router-dom';

function WidgetRecentPost({ thumb, title, meta, postLink }) {
  return (
    <div className="post-item">
      <Link
        to={postLink}
        className="post-thumb bg-cover"
        style={{ backgroundImage: `url(${thumb})` }}
      />
      <div className="post-info">
        <Link to={postLink} className="post-title">
          {title}
        </Link>
        <span className="post-meta"> {meta}</span>
      </div>
    </div>
  );
}

export default WidgetRecentPost;
