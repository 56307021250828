import { useEffect } from 'react';
import About1 from '../components/About1';
// import Facts from '../components/Facts';
import Hero from '../components/Hero';
// import Numbers from '../components/Numbers';
// import Queries from '../components/Queries';
import ServicesSection from '../components/ServicesSection';
// import Team from '../components/Team';
// import Testimonial from '../components/Testimonial';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero />
      <About1 />
      <ServicesSection />
      {/* <Facts /> */}
      {/* <Numbers /> */}
      {/* <Testimonial /> */}
      {/* <Team /> */}
      {/* <Queries /> */}
    </>
  );
}

export default Home;
