import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function ServicesCard({ heading, text, background }) {
  const showMore = false;

  return (
    <div className="col-lg-3 col-sm-6">
      <div
        className={
          background === 'white' ? 'services-card card-light' : 'services-card'
        }
      >
        <div className="card-content">
          <h3 className="card-heading">{heading}</h3>
          <p
            className={background === 'white' ? 'body-text' : 'body-text light'}
          >
            {text}&nbsp;
            <br />
            <br />
            <br />
          </p>
          {showMore && (
            <Link to="/services" className="button card-button">
              <span className="card-button-icon">
                <FaPlus />
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServicesCard;
